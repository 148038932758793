<template>
    
  <v-app-bar scroll-behavior="collapse" color="cyan-darken-1">
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </template>
    <v-app-bar-title>
        
        <v-img src="/img/logo.png" width="50"></v-img>
    </v-app-bar-title>
    

    <v-spacer></v-spacer>

    <v-toolbar-items class="d-none d-sm-block">
      <v-divider vertical></v-divider>
      <v-btn variant="text" @click="activarOpcionMenu('quienessomos')">Quienes somos</v-btn>

      <v-divider vertical></v-divider>

      <v-btn variant="text" @click="activarOpcionMenu('vision')">Visión</v-btn>

      <v-divider vertical></v-divider>

      <v-btn variant="text" @click="activarOpcionMenu('mision')">Misión</v-btn>
      <v-divider vertical></v-divider>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn icon @click="openLinkedin(linkedIn)">
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn icon @click="openWhatsApp(whatsapp, mensajeWhatsapp)">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn icon @click="openFacebook(facebook)">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn icon @click="openinstagram(instagram)">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>  
      <v-btn icon @click="home()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>  

    </v-toolbar-items>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    temporary
    class="gradient-background_menu_superior"
  >
    <v-list-item
      prepend-avatar="/img/LogoSolo.png"
      :title="user.name"
      nav
      @click="menuPrincipal()"
    >
      <template v-slot:append>
        <v-btn
          icon="mdi-chevron-left"
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-btn>
      </template>
    </v-list-item>

    <v-divider></v-divider>

    <v-list density="compact" nav>
        <v-list-item
        prepend-icon="mdi-home"
        title="Home"
        value="home"
        @click="home"
      ></v-list-item>
      <v-list-item
        :prepend-avatar="op.img"
        :title="op.nombre"
        :value="op.codigo"
        v-for="op in user.options"
        :key="op"
        @click="activarOpcionMenu(op.codigo)"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-logout"
        title="Salir"
        value="exit"
        @click="SalirSistema"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
  
  <script>
export default {
  data: () => ({
    drawer: true,
    rail: true,
    whatsapp: "56926393462",
    mensajeWhatsapp:
      "Hola, me interesa contactarte por que tengo interés en sus productos.",
    linkedIn: "in/raul-castro-22b7bb50/",
    facebook: "raul.c.nunez",
    instagram: "3035alexis/",
  }),
  props: {
    user: Object,
  },
  mounted: function () {},
  methods: {
    openWhatsApp: function (number, message) {
      // Formatear el número y el mensaje para la URL de WhatsApp
      let baseUrl = "https://wa.me/";
      let formattedNumber = number.replace(/[\s+()-]/g, ""); // Eliminar espacios, paréntesis y guiones
      let encodedMessage = encodeURIComponent(message); // Codificar el mensaje para URL

      // Crear la URL completa
      let url = `${baseUrl}${formattedNumber}?text=${encodedMessage}`;

      // Abrir la nueva ventana con la URL
      window.open(url, "_blank"); // '_blank' abre la URL en una nueva pestaña
    },
    openLinkedin: function (perfil) {
      let baseUrl = "https://www.linkedin.com/";

      let url = `${baseUrl}${perfil}`;
      window.open(url, "_blank"); // '_blank' abre la URL en una nueva pestaña
    },
    openFacebook: function (perfil) {
      let baseUrl = "https://web.facebook.com/";

      let url = `${baseUrl}${perfil}`;
      window.open(url, "_blank"); // '_blank' abre la URL en una nueva pestaña
    },
    openinstagram: function (perfil) {
      let baseUrl = "https://www.instagram.com/";

      let url = `${baseUrl}${perfil}`;
      window.open(url, "_blank"); // '_blank' abre la URL en una nueva pestaña
    },
    home: function(){
        this.$router.push("/");
    },
    menuPrincipal: function () {
      this.drawer = !this.drawer;
      this.$router.push("/menuPrincipal");
    },
    activarOpcionMenu: function (codigo) {
      this.$router.push(codigo);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
  
  <style>
.gradient-background_menu_superior {
  background: linear-gradient(to right, white, #00acc1);
  justify-content: center;
  align-items: top;
}
</style>