import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import primerabusqueda from '@/views/primerabusqueda.vue'
import nuevoProducto from '@/components/producto/nuevo.vue'
import listadoProducto from '@/components/producto/listado.vue'
import quienessomos from '@/components/front/quienessomos.vue';
import mision from '@/components/front/mision.vue';
import vision from '@/components/front/vision.vue';


const routes = [
  {
    path: '/',
    name: 'primerabusqueda',
    component: primerabusqueda
  },
  {
    path: '/nuevo/producto',
    name: 'nuevoProducto',
    component: nuevoProducto
  },
  {
    path: '/listadoProducto',
    name: 'listadoProducto',
    component: listadoProducto
  },
  {
    path: '/quienessomos',
    name: 'quienessomos',
    component: quienessomos
  },
  {
    path: '/mision',
    name: 'mision',
    component: mision
  },
  {
    path: '/vision',
    name: 'vision',
    component: vision
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
