<template>
    <v-row>
      <v-col cols="12" sm="3">
          <v-img src="/img/vision.png" width="100%"></v-img>
      </v-col>
      <v-col cols="12" sm="9">
          <div class="text-h6 ma-6">
            Nuestra visión es posicionarnos dentro del mercado chileno como un líder en la asistencia a usuarios finales mediante la tecnología y seguimiento de los productos requeridos.
          </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>