<template>
  <v-window
    v-model="onboarding"
    show-arrows
    v-if="length"
  >
    <v-window-item
      v-for="n in length"
      :key="`card-${n}`"
    >
      <v-card
        class="d-flex align-center justify-center ma-2"
        elevation="2"
        height="200"
      >
        <h1
          class="text-h2"
        >
          Producto Destacado {{ n }}
        </h1>
      </v-card>
    </v-window-item>
  </v-window>
</template>

<script>
export default {
    data: () => ({
        length: 3,
      onboarding: 0,
    })
}
</script>

<style>

</style>