<template>
  <v-container>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias nisi doloremque ex natus ipsam vel mollitia, autem saepe architecto nesciunt magni officia amet temporibus blanditiis sequi minima beatae. Porro, incidunt.
  </v-container>
</template>

<script>
export default {
    data: () => ({
    })
}
</script>

<style>

</style>