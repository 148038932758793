<template>
  <v-row>
    <v-col cols="12" sm="3">
        <v-img src="/img/quienes_somos.png" width="100%"></v-img>
    </v-col>
    <v-col cols="12" sm="9">
        <div class="text-h6 ma-6">
          Repuestia es un proveedor líder de repuestos y accesorios para automóviles, que ofrece una amplia gama de productos de alta calidad para diversos modelos de vehículos. Con un enfoque en la satisfacción del cliente, Repuestia asegura la disponibilidad de componentes de suspensión, dirección y frenos, así como también bujías, filtros, baterías, entre otros productos, todos ellos de primer nivel y a precios muy competitivos. 
Disponemos de varios canales de venta asistida y autogestionada. Manténgase a la vanguardia con las últimas ofertas de Repuestia en mantenimiento y reparación de automóviles, respaldadas por nuestro compromiso con la excelencia en el servicio y la calidad del producto.
        </div>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>