<template>
  <v-row>
    <v-col cols="12" sm="3">
        <v-img src="/img/mision.png" width="100%"></v-img>
    </v-col>
    <v-col cols="12" sm="9">
        <div class="text-h6 ma-6">
            Nuestra misión es ofrecerles a nuestros clientes la más amplia selección de repuestos automotrices originales y alternativos a los mejores precios posibles y con el mejor servicio en términos de capacidad, profesionalismo y asistencia.
        </div>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>