<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-img src="/img/logo.png" width="100"></v-img>
      </v-col>
      <v-col cols="10">
        <textos
          labelSet="Busqueda de producto"
          iconoSet="mdi-magnify"
          MensajeAyudaSet="Ingrese el nombre producto, marca, modelo y/o año"
          @busquedaTexto="buscandoValoresOnLine"
        ></textos>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" md="4" v-for="pro in productosEncontrados" :key="pro">
        <productoEncontrado :producto="pro"></productoEncontrado>
      </v-col>
      <v-col cols="12" v-if="mensajeNo !== null">
        {{ mensajeNo }}
      </v-col>
    </v-row>
    <home></home>
  </v-container>
</template>

<script>
import textos from "@/components/forms/textos.vue";
import productoEncontrado from "@/components/producto/encontrado.vue";
import { obtenerJsonGET } from "@/helpers/funcionesEstandard";

import home from "@/components/front/home.vue";

export default {
  data: () => ({
    timeout: null,
    productosEncontrados: [],
    mensajeNo: null,
  }),
  components: {
    textos,
    productoEncontrado,
    home,
  },
  methods: {
    buscandoValoresOnLine: await function (text) {
      if (text) {
        if (text.length > 2) {
          // Clear the previous timeout if it exists
          if (this.timeout) {
            clearTimeout(this.timeout);
          }

          // Set a new timeout
          this.timeout = setTimeout(() => {
            this.onComboboxChange(text);
          }, 500);
        } else {
          this.productosEncontrados = [];
          this.mensajeNo = null;
        }
      } else {
        this.productosEncontrados = [];
        this.mensajeNo = null;
      }
    },
    onComboboxChange: async function (text) {
      var SendData = {
        text: text,
      };
      var resp = await obtenerJsonGET("product/search", SendData);
      if (resp) {
        this.productosEncontrados = resp.data;
        if (resp.data.length <= 0) {
          this.mensajeNo = "Producto no encontrado...";
        } else {
          this.mensajeNo = null;
        }
      }
    },
  },
};
</script>

<style>
</style>