<template>
    <!--v-app-bar scroll-behavior="collapse"
        density="compact">
      <v-app-bar-title>
            <v-img src="/img/logo.png" width="50"></v-img>
        Texto que se requiera
    </v-app-bar-title>
    </v-app-bar-->
  </template>
  
  <script>
  export default {
    data: () => ({
    }),
  };
  </script>
  